import './App.css';
import One from './pages/One';
import img_catalogo from './assets/pag_catalogo.png'
import img_contador from './assets/pag_contador.png'
import img3 from './assets/pineaple.jpg'
import { FcFolder } from "react-icons/fc";
// marcuxo

function App() {
  
  
  return (
    <>
      <div className="App" id='main'>
        <header className="App-header">
          <h1>Portafolio DevOps</h1>
          <ul>
            <li><a href='#paginaOne'><FcFolder /> Page One</a></li>
            <li><a href='#paginaTwo'><FcFolder /> Page Two</a></li>
            <li><a href='#paginaThee'><FcFolder /> Page Thee</a></li>
            <li><a href='#paginaFour'><FcFolder /> Page Four</a></li>
            <li><a href='#paginaFive'><FcFolder /> Page Five</a></li>
            <li><a href='#paginaSix'><FcFolder /> Page Six</a></li>
          </ul>
        </header>
      </div>
      <One _id={'paginaOne'} tittle={'Catalogo'} bgImg={img_catalogo} />
      <One _id={'paginaTwo'} tittle={'Contador'} bgImg={img_contador} />
      <One _id={'paginaThee'} tittle={'PaginaTres'} bgImg={img3} />
      <One _id={'paginaFour'} tittle={'PaginaCuatro'} bgImg={img3} />
      <One _id={'paginaFive'} tittle={'PaginaCinco'} bgImg={img3} />
      <One _id={'paginaSix'} tittle={'PaginaSeis'} bgImg={img3} />
      {/* <div style={{backgroundImage: `url(${img1})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundPosition: 'center', height:"100vh"}}>texto</div> */}
    </>
  );
}


export default App;
