import React, { useEffect, useState } from 'react'
import { FcFolder } from "react-icons/fc";

export interface Props {
  _id: string,
  tittle: string,
  bgImg: string
}

const One:React.FC<Props> = (Props) => {
  const [IsWidth, setIsWidth] = useState<boolean>(false)
  // console.log(Props)
  
  useEffect(() => {
    if(window.navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)){
      setIsWidth(true)
    }else{setIsWidth(false)}
  }, [])
  
  return (
    <>
        <div className="paginaMain"  id={Props._id} style={
          {
            // backgroundImage: `url(${Props.bgImg})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundAttachment: 'fixed',
            // // backgroundPosition: 'center',
            // backgroundSize: 'cover'
            // background: 'gray'
          }
        }>
          <div className="irregular"></div>

          <div className="texto-pag">
            
            <div className="img-card">
              <img src={Props.bgImg} className="img-pag"/>
            </div>

            <div className="cnt-pag">
              <p><FcFolder /> {Props.tittle}</p>
              <p className='font-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque eos fugiat, labore cum maxime iure eius molestias, temporibus aliquid suscipit nulla. Expedita sequi aut, atque quas nostrum iure dolores esse doloremque illum veniam est sunt eum ea, vero quod natus nesciunt possimus aspernatur, fuga magni nemo totam recusandae iste? Exercitationem.</p>
              <a href='#main' style={{color:'gray'}}>volver</a>
            </div>
          </div>
        </div>
      
    </>
  )
}

export default One